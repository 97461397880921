/* Modern styling for EditFamilyMember form */
.family-member-container {
  padding: 16px;
  background-color: #f9fafb;
}

.family-member-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
}

/* Add smooth transitions */
.MuiButton-root, .MuiTextField-root, .MuiFormControl-root {
  transition: all 0.3s ease !important;
}

/* Add subtle hover effects */
.MuiPaper-root:hover {
  transform: translateY(-2px);
  transition: transform 0.3s ease;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
}

/* Section headers */
.section-header {
  font-weight: 500;
  color: #3498db;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid #3498db;
  display: inline-block;
}

/* Form controls styling */
.form-control-styled {
  margin-bottom: 16px;
}

.form-control-styled .MuiOutlinedInput-root {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.form-control-styled .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3498db;
}

.form-control-styled .MuiInputLabel-root {
  color: #7f8c8d;
}

/* Button styling */
.action-button {
  border-radius: 8px;
  text-transform: none;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
}

.save-button {
  background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.save-button:hover {
  background: linear-gradient(45deg, #1976D2 30%, #00B0FF 90%);
  box-shadow: 0 6px 10px rgba(50, 50, 93, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.delete-button {
  color: #f44336;
  border-radius: 8px;
  text-transform: none;
  padding: 6px 16px;
  box-shadow: 0 4px 6px rgba(244, 67, 54, 0.25);
}
