@import url('https://fonts.googleapis.com/css?family=Anton');
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz@16..144&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@font-face {
    font-family: "Headervwwbd";
    src: local("Change-Regular"), url("./fonts/Changa-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}